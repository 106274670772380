import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../../shared/theme'

export const SectionCopy = styled.p`
	font-size: 14.8px;
	line-height: 17.8px;
	font-weight: 300;
	margin-bottom: 40px;
	color: ${getColor('white')};

	@media ${breakpoint('mobile')} {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 45px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 20px;
		line-height: 28px;
	}

	@media ${breakpoint('desktop')} {
		font-size: 25px;
		line-height: 30px;
	}
`

export const Copy = ({ styles, children }) => {
	return <SectionCopy style={{ ...styles }}>{children}</SectionCopy>
}

export default Copy
