import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const HeroSection = styled.section`
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	background-color: ${getColor('black')};
`

const HeroSectionInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100vw;
	background-position: center center;
	background-size: cover;
	position: relative;

	@media ${breakpoint('laptop')} {
		background-repeat: no-repeat;
	}
`

const ContentWrap = styled.div`
	text-align: center;
	color: ${getColor('white')};
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 35px;

	@media ${breakpoint('tablet')} {
		max-width: 1100px;
	}
`

const Heading = styled.h1`
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	font-weight: 800;
	margin: 0 auto;

	@media ${breakpoint('tablet')} {
		font-size: 45px;
		line-height: 56px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 70px;
		line-height: 84px;
	}
`

const CTA = styled.a`
	position: absolute;
	left: 50%;
	bottom: 25%;
	transform: translateX(-50%);
	border: 2px solid ${getColor('orange')};
	padding: 20px 16px 20px 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${getColor('white')};
	transition: all ${(props) => props.theme.transitionSpeed.default};

	&:hover {
		background-color: ${getColor('orange')};
		border-color: ${getColor('orange')};
	}

	@media ${breakpoint('mobile')} {
		padding: 24px;
	}

	@media ${breakpoint('tablet')} {
		bottom: 15%;
	}

	@media ${breakpoint('desktop')} {
		bottom: 20%;
	}

	svg {
		width: 12px;
		height: 12px;
		margin-left: 20px;

		polyline,
		line {
			stroke: ${(props) =>
				props.colortheme == 'white' ? getColor('black') : getColor('white')};
			transition: stroke ${(props) => props.theme.transitionSpeed.default};
			stroke-width: 2;
			stroke-miterlimit: 10;
			stroke-linecap: 'square';

			${(props) =>
				props.colortheme == 'switch' &&
				css`
					stroke: ${getColor('black')};
				`}
		}

		@media ${breakpoint('tablet')} {
			width: 16px;
			height: 16px;
			margin-left: 40px;

			polyline,
			line {
				${(props) =>
					props.colortheme == 'switch' &&
					css`
						stroke: ${getColor('white')};
					`}
			}
		}
	}

	/* &:hover {
		svg {
			polyline,
			line {
				stroke: ${(props) =>
		props.colortheme == 'white' ? getColor('white') : getColor('black')};
				${(props) =>
		props.colortheme == 'switch' &&
		css`
			stroke: ${getColor('white')};
		`}
			}
		}

		transition: all ${(props) => props.theme.transitionSpeed.default};
	} */
`

const CtaCopy = styled.span`
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;

	@media ${breakpoint('mobile')} {
		font-size: 20px;
		line-height: 22px;
	}
`

export const HeroImage = ({ image, mobileImage, copy, cta }) => {
	if (!image) {
		return
	}

	const [isMobileView, setIsMobileView] = useState()

	useEffect(() => {
		setIsMobileView(window.innerWidth <= 889)

		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 889)
		}

		// Add event listener for window resize
		window.addEventListener('resize', handleResize)

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<HeroSection>
			{isMobileView && mobileImage ? (
				<HeroSectionInner
					style={{ backgroundImage: `url(${mobileImage.mediaItemUrl})` }}
				>
					<ContentWrap>
						<Heading>{copy && copy}</Heading>
					</ContentWrap>
					{cta && (
						<CTA href={cta.url} target={cta.target}>
							<CtaCopy>{cta.title}</CtaCopy>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4 18.5">
								<polyline fill="none" points="7.6,17.1 15,9.3 7.6,1.4 " />
								<line fill="none" x1="15" y1="9.3" x2="0" y2="9.3" />
							</svg>
						</CTA>
					)}
				</HeroSectionInner>
			) : (
				<HeroSectionInner
					style={{ backgroundImage: `url(${image.mediaItemUrl})` }}
				>
					<ContentWrap>
						<Heading>{copy && copy}</Heading>
					</ContentWrap>
					{cta && (
						<CTA href={cta.url} target={cta.target}>
							<CtaCopy>{cta.title}</CtaCopy>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4 18.5">
								<polyline fill="none" points="7.6,17.1 15,9.3 7.6,1.4 " />
								<line fill="none" x1="15" y1="9.3" x2="0" y2="9.3" />
							</svg>
						</CTA>
					)}
				</HeroSectionInner>
			)}
		</HeroSection>
	)
}

HeroImage.propTypes = {
	image: PropTypes.object,
	mobileImage: PropTypes.object,
	copy: PropTypes.string,
}

HeroImage.defaultProps = {
	image: {},
	mobileImage: {},
	copy: '',
}
