import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../../shared/theme'

export const SectionsHeading = styled.h2`
	font-size: 27.5px;
	line-height: 33px;
	font-weight: 800;

	@media ${breakpoint('mobile')} {
		font-size: 36px;
		line-height: 44px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 50px;
		line-height: 60px;
	}
`

export const Heading = ({ styles, children }) => {
	return <SectionsHeading style={{ ...styles }}>{children}</SectionsHeading>
}

export default Heading
