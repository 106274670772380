import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const Container = styled.div`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	max-width: 1440px;
	margin: 0 auto;
	display: flex;

	@media ${breakpoint('mobile')} {
		background-position: center;
	}
`

export const BackgroundImage = ({ styles, backgroundImage, children }) => {
	return (
		<Container
			style={{
				...styles,
				backgroundImage: `url(${backgroundImage.mediaItemUrl})`,
			}}
		>
			{children}
		</Container>
	)
}

export default BackgroundImage
