import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

import arrowImage from '../../assets/images/Arrow-1.png'

const Link = styled.a`
	border: 1px solid ${getColor('white')};
	padding: 24px 16px 19px 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${getColor('white')};
	width: 100%;

	@media ${breakpoint('mobile')} {
		padding: 24px 16px 19px 18px;
		/* width: 50%; */
	}

	@media ${breakpoint('tablet')} {
		/* width: 40%; */
	}

	@media ${breakpoint('laptop')} {
		/* width: 30%; */
	}
`

const LinkCopy = styled.span`
	text-transform: uppercase;
	font-size: 8.8px;
	line-height: 10.6px;
	font-weight: 700;

	@media ${breakpoint('mobile')} {
		font-size: 12px;
		line-height: 14.4px;
	}
`

const LinkIcon = styled.img`
	height: 10px;
	width: 10px;

	@media ${breakpoint('mobile')} {
		height: 13px;
		width: 13px;
	}
`

export const SectionsButtonLink = ({ link, styles }) => {
	if (!link?.url) {
		return null
	}

	return (
		<Link href={link.url} target={link.target} style={{ ...styles }}>
			<LinkCopy>{link.title}</LinkCopy>
			<LinkIcon src={arrowImage} />
		</Link>
	)
}
