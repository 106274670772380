import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'
import ContentContainer from '../contentContainer'

const SectionWrapper = styled.div`
	padding: 61px 0;

	@media ${breakpoint('tablet')} {
		padding: 90px 0 100px;
	}

	@media ${breakpoint('laptop')} {
		padding: 152px 0 162px;
	}
`

const Copy = styled.p`
	font-size: 17.5px;
	font-weight: 300;
	line-height: 21px;
	text-align: center;
	margin: 0 auto;

	@media ${breakpoint('mobile')} {
		font-size: 25px;
		line-height: 30px;
	}

	@media ${breakpoint('tablet')} {
		font-size: 30px;
		line-height: 36px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 41px;
		font-weight: 400;
		line-height: 49px;
		max-width: 1040px;
	}
`

export const HomeIntro = ({ copy, backgroundColour }) => {
	const textColour = backgroundColour === '#000000' ? '#ffffff' : '#000000'

	return (
		<SectionWrapper style={{ backgroundColor: backgroundColour }}>
			<ContentContainer>
				<Copy style={{ color: textColour }}>{copy}</Copy>
			</ContentContainer>
		</SectionWrapper>
	)
}

export default HomeIntro
