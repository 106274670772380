import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import { SectionsHeading } from '../../elements/typography/sectionHeading'
import { SectionCopy } from '../../elements/typography/sectionCopy'
import { SectionsButtonLink } from '../../elements/sectionsButtonLink'
import ContentContainer from '../contentContainer'
import { getImage } from '../../utils/getData'

const SectionsIntroSection = styled.section``

const SectionsIntroWrapper = styled.div`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	margin: 0 auto;
	display: flex;

	@media ${breakpoint('tablet')} {
		background-position: center;
		height: 481px;
		height: ${(props) => (props.extraHeadingWidth ? '481px' : 'auto')};
	}

	@media ${breakpoint('laptop')} {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
`

const ContentInner = styled.div`
	margin: 54px 0px;
	width: 66.8%;
	color: ${getColor('white')};

	@media ${breakpoint('tablet')} {
	}

	@media ${breakpoint('laptop')} {
		margin: ${(props) =>
			props.extraHeadingWidth ? '53px 0px 57px' : '75px 0px'};
		width: 50%;
	}
`

const CopyWrapper = styled.div`
	@media ${breakpoint('tablet')} {
		margin-bottom: 10px;
	}
`

const HeadingWrapper = styled.div`
	margin-bottom: 20px;
	width: ${(props) => (props.extraHeadingWidth ? '100%' : '120%')};

	@media ${breakpoint('laptop')} {
		width: ${(props) => (props.extraHeadingWidth ? '130%' : '100%')};
		margin-bottom: 32px;
	}
`

const ButtonWrapper = styled.div`
	width: 80%;

	@media ${breakpoint('mobile')} {
		width: 50%;
	}

	@media ${breakpoint('tablet')} {
		width: 40%;
	}

	@media ${breakpoint('laptop')} {
		width: 30%;
	}
`

export const HomeSectionsIntro = ({
	heading,
	copy,
	link,
	backgroundImage,
	backgroundColour,
	contentPosition,
	extraHeadingWidth,
}) => {
	console.log(heading, extraHeadingWidth)

	return (
		<SectionsIntroSection
			style={{ backgroundColor: backgroundColour ? backgroundColour : '#000' }}
		>
			<SectionsIntroWrapper
				style={{
					backgroundImage: `url(${getImage(backgroundImage)})`,
					justifyContent:
						contentPosition === 'right' ? 'flex-end' : 'flex-start',
				}}
				extraHeadingWidth={extraHeadingWidth}
			>
				<ContentContainer style={{ display: 'flex', alignItems: 'center' }}>
					<ContentInner extraHeadingWidth={extraHeadingWidth}>
						<HeadingWrapper extraHeadingWidth={extraHeadingWidth}>
							<SectionsHeading>{heading && heading}</SectionsHeading>
						</HeadingWrapper>
						<CopyWrapper>
							<SectionCopy>{copy && copy}</SectionCopy>
						</CopyWrapper>
						<ButtonWrapper>
							<SectionsButtonLink link={link && link} />
						</ButtonWrapper>
					</ContentInner>
				</ContentContainer>
			</SectionsIntroWrapper>
		</SectionsIntroSection>
	)
}

export default HomeSectionsIntro
