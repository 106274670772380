import React, { useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import Heading from '../../elements/typography/sectionHeading'
import ContentContainer from '../contentContainer'
import getStyle from '../../utils/getStyle'
import Copy from '../../elements/typography/sectionCopy'

const SectionWrapper = styled.section`
	padding: 54px 0 58px;

	@media ${breakpoint('tablet')} {
		padding: 74px 0 92px;
	}

	@media ${breakpoint('laptop')} {
		padding: 97px 0 133px;
	}
`
const SectionHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 54px;
	width: 66.8%;
`

const HeadingWrapper = styled.div`
	margin-bottom: 20px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 36px;
	}
`

const SectionBody = styled.div`
	form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		& > div {
			width: 100%;
			margin-bottom: 25px;

			@media ${breakpoint('tablet')} {
				width: 48%;
			}

			input,
			select {
				box-shadow: none;
				border-bottom: 2px solid ${getColor('white')};
				padding-bottom: 0.5em;
				transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
				line-height: 1.65;
				width: 100%;
				color: ${getColor('white')};

				&.invalid {
					border-color: ${getColor('orange')};
				}
			}

			&.hs_hs_persona {
				div {
					position: relative;

					&::after {
						content: '';
						display: inline-block;
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-top: 6px solid ${getColor('white')};
						margin-left: 5px;
						position: absolute;
						right: 10px;
						top: 15px;
					}
				}

				label {
					margin-bottom: 10px;

					@media ${breakpoint('tablet')} {
						margin-bottom: 10px;
					}
				}

				legend {
					margin-bottom: 24px;

					@media ${breakpoint('tablet')} {
						margin-bottom: 42px;
					}
				}
			}

			&.hs_message {
				width: 100%;
				margin-bottom: 15px;

				@media ${breakpoint('tablet')} {
					margin: 40px 0;
				}

				textarea {
					border: 2px solid ${getColor('white')};
					color: ${getColor('white')};
					height: 252px;
					width: 100%;
					padding: 10px;

					@media ${breakpoint('tablet')} {
						height: 275px;
					}
				}
			}

			&.hs_submit {
				input {
					background-color: ${getColor('black')};
					border: 2px solid ${getColor('white')};
					color: ${getColor('white')};
					font-weight: 800;
					text-transform: uppercase;
					text-align: left;
					position: relative;
					grid-column: -1 / 1;
					font-size: 8px;
					padding: 23px 64px 24px;
					width: auto;
					transition: all 0.2s ease-in-out;

					&:hover {
						color: ${getColor('black')};
						background-color: ${getColor('white')};
					}

					@media ${breakpoint('tablet')} {
						padding: 30px 100px 29px;
						font-size: 12px;
					}
				}
			}

			label {
				margin-bottom: 24px;
				font-size: 12px;
				display: block;
				color: ${getColor('white')};
				@media ${breakpoint('tablet')} {
					margin-bottom: 42px;
					font-size: 14px;
				}

				@media ${breakpoint('desktop')} {
					font-size: 18px;
				}
			}

			legend {
				font-size: 10px;
				color: ${getColor('white')};
			}

			.hs-error-msg {
				margin-top: 1em;
				font-size: 10px;
				color: rgb(255, 77, 16);
				margin-bottom: 0;
				transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
			}
		}
	}

	.submitted-message {
		color: ${getColor('white')};
	}
`

export const ContactFormNew = ({ backgroundColour, heading, copy }) => {
	const textColour = backgroundColour === '#000000' ? 'white' : 'black'

	useEffect(() => {
		const script = document.createElement('script')
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.onload = () => {
			if (window.hbspt) {
				const form = window.hbspt.forms.create({
					region: 'na1',
					portalId: '44219446',
					formId: 'a28f2bd1-f2f8-4c93-917c-53178c7370a7',
					target: '#contactForm',
				})
			}
		}

		document.body.appendChild(script)

		// Clean up the script tag on component unmount
		return () => {
			document.body.removeChild(script)
		}
	})

	return (
		<SectionWrapper style={{ backgroundColor: getStyle(backgroundColour) }}>
			<ContentContainer>
				<SectionHeader>
					<HeadingWrapper>
						<Heading styles={{ color: textColour }}>
							{heading && heading}
						</Heading>
					</HeadingWrapper>
					<Copy styles={{ color: textColour, marginBottom: '0' }}>
						{copy && copy}
					</Copy>
				</SectionHeader>

				<SectionBody
					color={textColour}
					className="hubspot-form"
					id="contactForm"
				></SectionBody>
			</ContentContainer>
		</SectionWrapper>
	)
}

export default ContactFormNew
